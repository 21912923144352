import React from 'react'
import Button from '../button'
import { IconFacebook, IconInstagram, IconLinkedIn, IconTwitter } from '../icons'
import Link from '../link'

const iconSize = 15;

function Footer() {
    function goTo(link: string) {
        window.open(link, '_blank')
    }

    return (
        <div className='footer'>
            <div className='ft-content'>
                <Link url='https://docs.ChuttApp.com/help' text='Help' blank anchor />
                <Link url='/about' text='About' />
                <Link url='https://intelligencepool.com/contact' anchor blank text='Contact' />
                <Link url='/terms' text='Terms of Service' />
                <Link url='/privacy' text='Privacy Policy' />
                <div className='socials flex'>
                    <Button onClick={() => goTo('https://www.linkedin.com/company/ChuttApp')} fancy icon={<IconLinkedIn size={iconSize} />} />
                    <Button onClick={() => goTo('https://web.facebook.com/profile.php?id=100084227971987')} fancy icon={<IconFacebook size={iconSize} />} />
                    <Button onClick={() => goTo('https://twitter.com/ChuttApp')} fancy icon={<IconTwitter size={iconSize} />}  />
                    <Button onClick={() => goTo('https://instagram.com/ChuttApp?igshid=YmMyMTA2M2Y=')} fancy icon={<IconInstagram size={iconSize} />} />
                </div>
            </div>
        </div>
    )
}

export default Footer