import React from 'react'

function Money() {
    return (
        <div className='money'>
            <div className='mn-content'>
                <div className='mn-cash'>
                    <div className='mbn-curr'>Ghc</div>
                    <div className='mbn-val'>230</div>
                </div>
            </div>
            <div className='mn-bottom'>SendPay Cash</div>
        </div>
    )
}

export default Money