import React from 'react'
import LogoSrc from '../../assets/svgs/logo.svg'


interface Props {
  size?: number;
  showText?: boolean;
  showGraphic?: boolean;
}

function Logo({ showGraphic = true, showText, size = 60 }: Props) {
  return (
    <div className='logo'>
      {showGraphic && <img style={{ height: size, width: size }} src={LogoSrc} alt='' />}
      {showText && <div style={{ fontSize: size / 2.5 }} className='logo-text'>ChuttApp</div>}
    </div>
  )
}

export default Logo