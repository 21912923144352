import React from 'react'
import { InAppGraphicAnimation, QRCodePayGraphicAnimation, SendTransferGraphicAnimation } from '../../../components';
import Button from '../../../components/button';
import { ReactComponent as Wave } from '../../../assets/svgs/wave.svg';


interface FeatureProps {
    icon: any;
    title: string;
    subtitle: string;
}

function Features() {
    const data = [
        {
            icon: <SendTransferGraphicAnimation />,
            title: 'Realtime transfers',
            subtitle: "Send money to your contacts or anyone you want at Whatsapp's speed!"
        },
        {
            icon: <InAppGraphicAnimation />,
            title: 'In-App services',
            subtitle: "Order rides, food, pay bills and more right from the ChuttApp app."
        },
        {
            icon: <QRCodePayGraphicAnimation />,
            title: 'QR Code Transfers',
            subtitle: "Accept or receive instant transfers by scanning QR Codes."
        },
    ];
    return (
        <div className='section'>
            <div className='wave flip'>
                <div><Wave /></div>
            </div>
            <div className='features-wrapper'>
                <div className='fancy-feature-hero bottom'>
                    <div className='fancy-card'>
                        <div className='fn-ft-title'>We blend <span>Social</span> with <span>Finance</span></div>
                        <div className='fn-ft-subtitle'>ChuttApp pretends to be your chat app, but on the note, it does more than you think.</div>
                    </div>
                </div>
                <div className='fancy-feature-connector'>
                    <div className='fancy-feature-line'></div>
                    <div className='fancy-feature-dot'></div>
                </div>
                <div className='features'>
                    {data.map((a, i) => <Feature icon={a.icon} title={a.title} subtitle={a.subtitle} />)}
                </div>
                <div className='fancy-feature-connector flip'>
                    <div className='fancy-feature-line'></div>
                    <div className='fancy-feature-dot'></div>
                </div>
                <div className='fancy-feature-hero top'>
                    <div className='fancy-card'>
                        <div className='fn-ft-title'>Are you a <span>Developer?</span></div>
                        <div className='fn-ft-subtitle'>Start <b>integrating</b> <span>ChuttApp</span> services in your apps. Or build in-app services for ChuttApp users!</div>
                        <div className='fn-actions'>
                            <Button onClick={() => window.open('https://docs.ChuttApp.com', '_blank')} type='solid' font='bold' text='See docs' padding={10} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Feature({ icon, title, subtitle }: FeatureProps) {
    return (
        <div className='feature'>
            <div className='ft-graphic'>{icon}</div>
            <div className='ft-title'>{title}</div>
            <div className='ft-subtitle'>{subtitle}</div>
        </div>
    )
}


export default Features