import React from 'react'
import { Footer, Header } from '../../components'
import BusinessHero from './components/BusinessHero'
import Features from './components/Features'
import Hero from './components/Hero'
import JobsAd from './components/JobsAd'

function WelcomePage() {
  return (
    <div className='welcome'>
        <Header />
        <Hero />
        <Features />
        <BusinessHero />
        <JobsAd />
        <Footer />
    </div>
  )
}

export default WelcomePage