import React from 'react'
import { IconMission, IconVision, MainWrapper } from '../../components'
import AboutHero from './components/AboutHero'

function AboutPage() {
    return (
        <MainWrapper>
            <div className='about'>
                <AboutHero />
                <div className='hero-container curved'>
                    <div className='hero-content'>
                        <div className='hero-left'>
                            <div className='hero-title'>
                                Mission
                            </div>
                            <p className='hero-subtitle'>
                                ChuttApp's mission is to make financial services easy and fun, mainly in Africa. 
                                {/* We are starting with Ghana and Nigeria but are eager to expand across Africa. */}
                            </p>
                        </div>
                        <div className='hero-right'>
                            <div className='hero-graphic small'>
                                <IconMission size={120} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hero-container curved flip'>
                    <div className='hero-content'>
                        <div className='hero-left'>
                            <div className='hero-title'>
                                Vision
                            </div>
                            <p className='hero-subtitle'>
                                <b>'WeChat for Africa!'</b>. Our vision is simple. <br/><br/> ChuttApp plans to widen its reach and create 
                                more opportunities, mainly in Africa. We strive to build a platform where Payment solutions in Africa
                                 is more convenient.
                            </p>
                        </div>
                        <div className='hero-right'>
                            <div className='hero-graphic small'>
                                <IconVision size={120} color="black" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

export default AboutPage