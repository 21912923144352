import React from 'react'
import { IconDevelopers, BusinessGraphicAnimation } from '../../../components';
import Button from '../../../components/button';

function BusinessHero() {
  const goTo = (url: string) => window.open(url, '_blank');

  return (
    <div className='hero-container curved flip'>
      <div className='hero-content'>
        <div className='hero-left'>
          <div className='hero-label'>
            <div>Business &amp; Fun</div>
            <div className='icon'><IconDevelopers color='var(--red)' /></div>
          </div>
          <div className='hero-title'>
            <span>Grow</span> your business with ChuttApp services.
          </div>
          <div className='hero-subtitle'>
            You can accept payment via your mobile phone, iPad / Tablets using QR Codes and One-Time-Bill methods!<br />
          </div>
          <div className='hero-actions'>
            <Button onClick={()=> goTo('https://app.ChuttApp.com')} type='solid' font='bold' text='Get started' padding={10} />
          </div>
        </div>
        <div className='hero-right'>
          <div className='hero-graphic'>
            <BusinessGraphicAnimation />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BusinessHero