import React from 'react'
import { Logo } from '../logo'
import Link from '../link'
import { IconDevelopers, IconOverview } from '../icons'

function Header() {
    return (
        <div className='header'>
            <div className='hd-bucket'>
                <a href='/'>
                    <div className='hd-left'>
                        <Logo size={70} showGraphic={false} showText />
                    </div>
                </a>
                <div className='center hd-right'>
                    <div className='center hd-links'>
                        <Link className='mob-nav' activeClassName='link-active' icon={<IconOverview />} url='/about' text='About' />
                        {/* <Link activeClassName='link-active' icon={<IconStorefront />} url='/business' text='Business' /> */}
                        <Link activeClassName='link-active' icon={<IconDevelopers />} blank url='https://docs.ChuttApp.com' text='Developers' anchor />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header