import React from 'react'
import {ReactComponent as Photo} from '../../../assets/svgs/logo.svg';

function AboutHero() {
    return (
        <div className='hero-container flip'>
            <div className='hero-content'>
                <div className='hero-left'>
                    <div className='hero-title'>
                        About ChuttApp
                    </div>
                    <p className='hero-subtitle'>
                        ChuttApp makes it easier for people to connect, send and
                        receive cash, shop around and do more on a single platform.<br/><br/>
                        People can get social, send and receive money from their
                        friends &amp; family or make payment transactions with
                        businesses.<br/><br/>
                        ChuttApp is built with Developers and Businesses in mind.
                        Developers can leverage on our API
                        infrastructure to integrate or build in-app services for Senddpey.
                    </p>
                </div>
                <div className='hero-right'>
                    <div className='hero-graphic'>
                        <Photo className='logo' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHero