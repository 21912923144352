import React from 'react'
import { Link as L, useResolvedPath, useMatch } from 'react-router-dom'
import { appendClass } from '../../utils';
import Click, { ClickableProps } from '../button/Click'


export interface LinkProps extends ClickableProps {
    url: string;
    activeClassName?: string;
    exact?: boolean;
    children?: any;
    anchor?: boolean;
    blank?: boolean;
}

export const Link: React.FC<LinkProps> = ({ url, children, title, className, exact = true, activeClassName, blank, anchor, ...props }) => {
    let resolved = useResolvedPath(url);
    let match = useMatch({ path: resolved.pathname, end: exact });


    if (!match) {
        activeClassName = undefined
    }

    const item = (
        children ??
        <Click className={appendClass("link", className)} {...props} />
    )

    if (anchor) return <a target={blank ? '_blank' : '_self'} href={`${url}`} rel="noreferrer">{item}</a>;

    return (
        <L title={title} className={appendClass('link', activeClassName)} to={url}>
            {item}
        </L>
    )
}


export default Link