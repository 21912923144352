import React from 'react'
import { ReactComponent as IOS } from '../../../assets/svgs/ios.svg';
import Android from '../../../assets/images/android.png';
import { WelcomeGraphicAnimation, IconSocial } from '../../../components';
import Avatar from '../../../components/avatar';
import ListItem from '../../../components/list';
import Money from './Money';
import man from '../../../assets/images/profiles/man.jpeg';
import girl from '../../../assets/images/profiles/girl.jpeg';

function Hero() {
  return (
    <div className='hero-container'>
      <div className='hero-content'>
        <div className='hero-left'>
          <div className='hero-label'>
            <div>Social &amp; Finance</div>
            <div className='icon'><IconSocial color='var(--red)' /></div>
          </div>
          <div className='hero-title'>
            <span>Connect</span> &amp; make <span> Payment</span> transactions
          </div>
          <div className='hero-subtitle'>
          ChuttApp makes it easier for people to connect, send and receive cash, shop around and do more on a single platform.
          </div>
          <div className='hero-actions'>
            <div className='get-btn'>
              <img src={Android} alt='' />
            </div>
            <div className='get-btn'>
              <IOS />
            </div>
          </div>
          <div className='hero-comments'>
            <ListItem left={<Avatar url={man} />} title="Bismark Paw" subtitle="Yo Abi! can you send the money?" />
            <div className='cm-divider'></div>
            <ListItem className='hh' left={<Avatar url={girl} />} title="Abigail" subtitle={<Money />} />
          </div>
        </div>
        <div className='hero-right'>
          <div className='hero-graphic'>
            <WelcomeGraphicAnimation />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero