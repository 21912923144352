import React from 'react'
import Lottie from "lottie-react";
import Welcome from "../../assets/lottie/welcome2.json";
import Developer from "../../assets/lottie/developer.json";
import InApp from "../../assets/lottie/inapp.json";
import SendTransfer from "../../assets/lottie/send.json";
import QRCodePay from "../../assets/lottie/qrcode1.json";
import Business from "../../assets/lottie/store.json";

export const WelcomeGraphicAnimation = ()=> {
    return <Lottie animationData={Welcome} />
}

export const DeveloperGraphicAnimation = ()=> {
    return <Lottie animationData={Developer} />
}

export const BusinessGraphicAnimation = ()=> {
    return <Lottie className='business-animation' animationData={Business} />
}


export const InAppGraphicAnimation = ()=> {
    return <Lottie animationData={InApp} />
}

export const QRCodePayGraphicAnimation = ()=> {
    return <Lottie animationData={QRCodePay} />
}

export const SendTransferGraphicAnimation = ()=> {
    return <Lottie animationData={SendTransfer} />
}