import { FiSun, FiSearch, FiHelpCircle, FiChevronDown, FiPlus } from 'react-icons/fi'
import { BiUser , BiStore} from 'react-icons/bi'
import { FaRegHandshake } from 'react-icons/fa'
import { IoSettingsOutline, IoTelescopeOutline, IoClose, } from 'react-icons/io5'
import { IoMdMoon, IoIosArrowForward, IoIosArrowBack, IoIosRefresh } from 'react-icons/io'
import { BsChatSquareDots, BsLinkedin, BsTwitter, BsInstagram, BsFacebook, BsCodeSlash, BsHeartFill, BsLink45Deg, BsArrowRight, BsArrowLeft, BsLock } from 'react-icons/bs'
import { GoHome } from 'react-icons/go'
import { GrGrow } from 'react-icons/gr'
import { VscTelescope } from 'react-icons/vsc'
import { MdDirectionsBike, MdElectricBike,  MdOutlineAlternateEmail } from 'react-icons/md'

interface Props {
    color?: string;
    size?: string | number
}

const s = 18;
const c = "var(--color-light)"



export const IconHome = ({ color = c, size = s }) => <GoHome color={color} size={size} />
export const IconBike = ({ color = c, size = s }) => <MdDirectionsBike color={color} size={size} />

export const IconPolicy = ({ color = c, size = s }) => <FaRegHandshake color={color} size={size} />

export const IconOverview = ({ color = c, size = s }) => <IoTelescopeOutline color={color} size={size} />
export const IconStorefront = ({ color = c, size = s }) => <BiStore color={color} size={size} />
export const IconDevelopers = ({ color = c, size = s }) => <BsCodeSlash color={color} size={size} />
export const IconCarrier = ({ color = c, size = s }) => <MdElectricBike color={color} size={size} />

export const IconAngleDown = ({ color = c, size = s }) => <FiChevronDown color={color} size={size} />

export const IconUser2 = ({ color = c, size = s }: Props) => <BiUser color={color} size={size} />;

export const IconSearch = ({ color = c, size = s }: Props) => <FiSearch color={color} size={size} />;

export const IconDarkTheme = ({ color = c, size = s }) => <IoMdMoon color={color} size={size} />
export const IconLightTheme = ({ color = c, size = s }) => <FiSun color={color} size={size} />
export const IconSetting = ({ color = c, size = s }) => <IoSettingsOutline color={color} size={size} />
export const IconSocial = ({ color = c, size = s }) => <BsHeartFill color={color} size={size} />


export const IconHelp = ({ color = c, size = s }) => <FiHelpCircle color={color} size={size} />
export const IconFeedback = ({ color = c, size = s }) => <BsChatSquareDots color={color} size={size} />


export const IconAngleLeft = ({ color = c, size = s }) => <IoIosArrowBack color={color} size={size} />
export const IconAngleRight = ({ color = c, size = s }) => <IoIosArrowForward color={color} size={size} />


export const IconLink = ({ color = c, size = s }) => <BsLink45Deg color={color} size={size} />

export const IconClose = ({ color = c, size = s }) => <IoClose color={color} size={size} />

export const IconRefresh = ({ color = c, size = s }) => <IoIosRefresh color={color} size={size} />
export const IconNext = ({ color = c, size = s }) => <BsArrowRight color={color} size={size} />
export const IconPrevious = ({ color = c, size = s }) => <BsArrowLeft color={color} size={size} />


export const IconPlus = ({ color = c, size = s }) => <FiPlus color={color} size={size} />

export const IconEmail = ({ color = c, size = s }) => <MdOutlineAlternateEmail color={color} size={size} />
export const IconPassword = ({ color = c, size = s }) => <BsLock color={color} size={size} />


export const IconLinkedIn = ({ color = c, size = s }) => <BsLinkedin color={color} size={size} />
export const IconFacebook = ({ color = c, size = s }) => <BsFacebook color={color} size={size} />
export const IconTwitter = ({ color = c, size = s }) => <BsTwitter color={color} size={size} />
export const IconInstagram = ({ color = c, size = s }) => <BsInstagram color={color} size={size} />


export const IconMission = ({ color = c, size = s }) => <GrGrow color={color} size={size} />
export const IconVision = ({ color = c, size = s }) => <VscTelescope color={color} size={size} />
