import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { WelcomePage } from './pages';
import AboutPage from './pages/extras/AboutPage';
import TermsPage from './pages/extras/TermsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<WelcomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/terms' element={<TermsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
