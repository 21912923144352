import React from 'react'
import Button from '../../../components/button'

function JobsAd() {
    return (
        <div className='section job'>
            <div className='list-card'>
                <div className='title'>We are hiring!</div>
                <div className='subtitle'>ChuttApp is fresh and hiring people in <span>Engineering</span> and <span>Business</span>.</div>
                <div className='action'>
                    <Button disabled onClick={()=> window.open('', '_blank')} type='solid' padding={10} text='See jobs (Paused)' />
                </div>
            </div>
        </div>
    )
}

export default JobsAd